<script lang="ts">
  import Footer from "$src/components/Footer/Footer.svelte";
  import Header from "$src/components/Header/Header.svelte";
  import { resolveLink } from "$src/lib/sanity";
  import "$src/styles";
  import type { Snippet } from "svelte";
  import type { LayoutServerData } from "./$types";

  let { children, data }: { data: LayoutServerData; children: Snippet } =
    $props();
</script>

<style>
  :global(#root) {
    min-height: 100svh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1;
  }
</style>

<Header
  nav={data.global.header.map((item) => ({
    label: item.label,
    href: resolveLink(item.link),
  }))}
/>

<main id="main" class="content --grid-page">
  {@render children()}
</main>

<Footer
  title={data.global.footer.title}
  copy={data.global.footer.copy}
  primaryCta={{
    href: resolveLink(data.global.footer.primaryCta.link),
    label: data.global.footer.primaryCta.label,
  }}
  secondaryCta={{
    href: resolveLink(data.global.footer.secondaryCta.link),
    label: data.global.footer.secondaryCta.label,
  }}
  primaryNav={data.global.footer.primaryNav.map(({ link, label }) => ({
    label,
    href: resolveLink(link),
  }))}
  secondaryNav={data.global.footer.secondaryNav.map(({ link, label }) => ({
    label,
    href: resolveLink(link),
  }))}
/>
